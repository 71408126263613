/* stylelint-disable-next-line at-rule-no-unknown */
.fixed {
  position: fixed;
}
.relative {
  position: relative;
}
.inline {
  display: inline;
}
.table {
  display: table;
}
.filter {
  filter: var(--tw-filter);
}
html,
body,
#root {
  width: 100%;
  height: 100%;
}
.block_a {
  display: block;
}
body {
  margin: 0;
  background: #f7f8fc;
}
div {
  box-sizing: border-box;
}
.icon {
  width: 1em;
  height: 1em;
  overflow: hidden;
  vertical-align: -0.15em;
  fill: currentColor;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #dadada;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
@font-face {
  font-family: 'PreferredTitleBlack';
  src: url(./static/PreferredTitleBlack.ccdd188b.ttf);
}
